
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}




//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    border: none;
    @include if( $border-radius, ( border-radius: $border-radius ) );
    color: $color-white;
    font-size: $h6 - .15;
    font-weight: $font-bold;
    padding: ($site-padding / 1.5) $site-padding * 1.25;
    text-align: center;
    transition: $transition;
}

%button-primary {
    background: linear-gradient(185deg, rgba(11,34,149,1) 0%, rgba(6,20,92,1) 100%);
}

%button-secondary {
    background-color: $color-secondary-dark;
}

%hover {
    background: linear-gradient(185deg, rgba(6,20,92,1) 0%, rgba(11,34,149,1) 100%);
    color: $color-white;
}

%hover-secondary {
    background: $color-primary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;
    @extend %button-primary;

    &:hover {
        @extend %hover;
    }
}

